import React, { Component } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import RegionsAll from "../components/regions/all-regions";
import RegionSingle from "../components/regions/single-region";
import { Router, navigate } from "@reach/router";

const workType = ["art", "writing"];
const dateCreated = new Date(Date.now());

export class RegionsContainer extends Component {
  state = {
    country: "USA",
    zip: "",
    noZip: false,
    interest: 0,
    regions: [],
    documents: [],
    navSection: false,
  };

  toggleSection = () => {
    this.setState({ navSection: !this.state.navSection });
  };

  // Async function queue to handle fetching multiple regions in sequence
  async queueAsyncFns(fns) {
    const values = [];
    await fns.reduce((previous, current, index, array) => {
      const thenable = index === 1 ? previous() : previous;
      return thenable.then((value) => {
        values.push(value);
        return index === array.length - 1
          ? current().then((value) => values.push(value))
          : current();
      });
    });
    return values;
  }

  // Fetch regions by country and zip
  getRegions = async (country, zip) => {
    try {
      console.log(`getRegions async`);
      const response = await fetch(
        `${process.env.GATSBY_PORTAL_REGION}/zip/${zip}?country=${country}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching regions:", error);
      return [];
    }
  };

  // Fetch single region by code
  getSingleRegion = async (code) => {
    try {
      console.log(`getSingleRegion try async`);
      const response = await fetch(`${process.env.GATSBY_PORTAL_REGION}/${code}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching single region:", error);
      return {};
    }
  };

  // Fetch both art and writing regions
  getBothRegions = async (country, zip, interest) => {
    try {
      console.log(`getBothRegions try async`);
      const regionCodes = await this.queueAsyncFns(
        workType.map(() => () => this.getRegions(country, zip))
      );

      console.log("Fetched region codes:", regionCodes);

      localStorage.setItem("zip", zip);
      localStorage.setItem("regionShortCode", regionCodes[interest].regionShortCode);

      const bothRegions = await this.queueAsyncFns(
        regionCodes.map((code) => () => this.getSingleRegion(code.regionShortCode))
      );

      console.log("Fetched both regions:", bothRegions);

      if (!bothRegions[interest].regionShortCode) {
        this.setState({ noZip: true });
      } else {
        const documents = bothRegions[interest].documents || [];
        this.setState(
          { regions: bothRegions, documents: documents },
          () => {
            console.log("Updated state with regions and documents:", this.state);
            const selectedShortCode = this.state.interest === 0
              ? localStorage.getItem("artRegionShortCode")
              : localStorage.getItem("writingRegionShortCode");

            const regionObject = bothRegions.find(
              (region) => region.code === selectedShortCode
            );

            console.log(
              this.state.interest === 0 ? "Art region:" : "Writing region:",
              regionObject
            );

            localStorage.setItem("selectedShortCode", regionObject.code);

            // Navigate after fetching documents
            this.setState({ documents: regionObject.documents || [] }, () => {
              console.log("line 150, documents =", this.state.documents);
              navigate(`/regions/${regionObject.code}`, {
                state: {
                  regions: this.state.regions,
                  documents: this.state.documents,
                },
              });
            });
          }
        );
      }
    } catch (error) {
      console.error("Error fetching both regions:", error);
    }
  };

  // Handle changes to country, zip, and interest
  handleCountryChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem("country", e.target.value);
    });
  };

  handleZipChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem("zip", e.target.value);
    });
  };

  handleInterestChange = (e) => {
    this.setState({ [e.target.name]: parseInt(e.target.value) }, () => {
      localStorage.setItem("interest", parseInt(e.target.value));
    });
  };

  // Form submission logic
  handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.clear();
    localStorage.setItem("date created", dateCreated);
    localStorage.setItem("interest", this.state.interest);
    localStorage.setItem("country", this.state.country);
    localStorage.setItem("zip", this.state.zip);

    this.setState({ noZip: false });

    try {
      const regionsFromZips = await this.getRegions(this.state.country, this.state.zip);
      console.log("Regions from Zips:", regionsFromZips);

      if (regionsFromZips.regions?.length > 0) {
        localStorage.setItem("artRegionShortCode", regionsFromZips.regions[0].code);
        localStorage.setItem("writingRegionShortCode", regionsFromZips.regions[1].code);

        const selectedShortCode = this.state.interest === 0
          ? localStorage.getItem("artRegionShortCode")
          : localStorage.getItem("writingRegionShortCode");

        const regionObject = regionsFromZips.regions.find(
          (region) => region.code === selectedShortCode
        );

        console.log(
          this.state.interest === 0 ? "Art region:" : "Writing region:",
          regionObject
        );

        localStorage.setItem("selectedShortCode", regionObject.code);

        // Fetch single region data and navigate
        const regionData = await this.getSingleRegion(regionObject.code);
        this.setState({
          regions: regionsFromZips.regions,
          documents: regionData.documents || [],
        }, () => {
          console.log("Updated regions and documents state:", this.state);
          navigate(`/regions/${regionObject.code}`, {
            state: {
              regions: this.state.regions,
              documents: this.state.documents,
            },
          });
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  findRegion = () => {
    navigate("/regions");
  };

  // Toggle between art and writing region
  // MODIFIED SWITCH REGION - 9/13/24
  switchRegion = async () => {
    // Determine the new interest (0 for art, 1 for writing)
    let newInterest = this.state.interest === 0 ? 1 : 0;
  
    // Get the region shortcodes from localStorage
    const artRegionShortCode = localStorage.getItem("artRegionShortCode");
    const writingRegionShortCode = localStorage.getItem("writingRegionShortCode");
    const selectedShortCode = localStorage.getItem("selectedShortCode");
  
    // Logging the shortcodes and current interest
    console.log("Current selectedShortCode:", selectedShortCode);
    console.log("Art Region ShortCode:", artRegionShortCode);
    console.log("Writing Region ShortCode:", writingRegionShortCode);
    console.log("New Interest:", newInterest);
  
    // Determine the new region shortcode based on current selection
    let newRegionShortCode;
    if (selectedShortCode === artRegionShortCode) {
      newRegionShortCode = writingRegionShortCode;
    } else {
      newRegionShortCode = artRegionShortCode;
    }
  
    console.log("New Region ShortCode:", newRegionShortCode);
  
    // Ensure the regions are available or fetch them again if needed
    if (this.state.regions.length === 0 && artRegionShortCode) {
      try {
        await this.getBothRegions(localStorage.getItem("country"), localStorage.getItem("zip"), newInterest);
        console.log("Fetched regions after switch:", this.state.regions);
      } catch (error) {
        console.error("Error fetching regions during switch:", error);
        return;
      }
    }
  
    // Fetch the new region's data based on the new shortcode
    try {
      const newRegionData = await this.getSingleRegion(newRegionShortCode); // Assuming getSingleRegion fetches region data
      console.log("Fetched new region data:", newRegionData);
  
      this.setState({
        interest: newInterest,
        regions: this.state.regions, // Assuming `regions` state is already updated with both art and writing regions
        selectedRegion: newRegionData, // Update with the new region data
        documents: newRegionData.documents || [], // Set documents directly from newRegionData
      });
  
      // Logging the updated state after fetching the new region
      console.log("Updated regions in state:", this.state.regions);
      console.log("Updated documents in state:", this.state.documents);
  
      // Update localStorage with the new selected shortcode and interest
      localStorage.setItem("selectedShortCode", newRegionShortCode);
      localStorage.setItem("interest", newInterest);
  
      // Navigate to the newly selected region
      navigate(`/regions/${newRegionShortCode}`, {
        state: {
          regions: this.state.regions,
          documents: newRegionData.documents || [], // Pass documents from the newly fetched region
        },
      });
    } catch (error) {
      console.error("Error fetching new region data:", error);
    }
  };

  render() {
    return (
      <Layout toggleSection={this.toggleSection}>
        {this.state.regions.length > 0 ? (
          <Seo
            title={this.state.regions[this.state.interest].name}
            description={`${this.state.regions[this.state.interest].name} is managed by ${this.state.regions[this.state.interest].partnerName}`}
          />
        ) : (
          <Seo title="My Region" description="Find your Region or view all Regions" />
        )}
        <Router>
          <RegionsAll
            path="/regions"
            handleCountryChange={this.handleCountryChange}
            handleZipChange={this.handleZipChange}
            handleInterestChange={this.handleInterestChange}
            handleSubmit={this.handleSubmit}
            noZip={this.state.noZip}
            interest={this.state.interest}
          />
          <RegionSingle
            path="/regions/:id/*"
            regions={this.state.regions}
            documents={this.state.documents} // Pass documents down to the single region component
            interest={this.state.interest}
            zip={this.state.zip}
            findRegion={this.findRegion}
            switchRegion={this.switchRegion}
            navSection={this.state.navSection}
          />
        </Router>
      </Layout>
    );
  }
}

export default RegionsContainer;