import React, { Component } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
export default class RegionAbout extends Component {
 

  render() {
    // const { documents } = this.props; // Access documents from props

    return (
<>

        <section id="region-page" className="page-pad">
          <div className="page">
          <div className="column-side">
              <div id="region-deadline" className="box special">
                {this.props.interest === 0 ? <h4>Art Deadline</h4> : this.props.interest === 1 ?  <h4>Writing Deadline</h4> : <h4>Region Deadline</h4> }
                {new Date(
                  `${this.props.data.submissionDeadlineDate}`
                ).toDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
                <div className="detail">
                  {new Date(
                    `${this.props.data.submissionDeadlineDate}`
                  ).toLocaleTimeString('en-US', {timeZoneName: 'short'})} 
                </div>
              </div>
            </div>

            {/* New column-side for documents
            <div className="column-side">
                <div id="region-documents" className="box">
                  <h4>Documents in ABOUT</h4>
                  {documents && documents.length > 0 ? (
                    <ul>
                      {documents.map((doc, index) => (
                        <li key={index}>
                          <a href={doc.key} target="_blank" rel="noopener noreferrer">
                            {doc.title || `Document ${index + 1}`}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No documents available.</p>
                  )}
                </div>
              </div> */}

            <div className="column-main">
              <h3>About This Region</h3>
              <span
                dangerouslySetInnerHTML={{ __html: this.props.data.about }}
              />
            </div>
            <div className="column-side">
              <div id="region-partner-info" className="box">
                <div
                  id="region-partner-logo"
                  style={{
                    backgroundImage: "url(" + this.props.data.logoKey + ")",
                  }}
                ></div>

<div id="region-partner-address">
                    {this.props.data.name}
                    <br />
                    Attn:&nbsp;
                    {this.props.data.contactName
                      ? this.props.data.contactName
                      : null}
                    <br />
                    {this.props.data.contactPhone
                      ? this.props.data.contactPhone
                      : null}
                    <br />
                  </div>
                <div id="region-partner-contact">
				{this.props.data.contactEmail ? (
                      <OutboundLink
                        href={`mailto:${this.props.data.contactEmail}?subject=Art%20%26%20Writing%20Awards`}
                        className="button"
                      >
                        Send Message
                      </OutboundLink>
                    ) : null}
                </div>
              </div>

  
            
			</div>
          </div>

          {/* Updated: Suraj accessed sponsoredKeys key to render sponsor logo,
          removed wrapping OutboundLink component, as v1 requires no link to sponsor page per programs team 11/8 */}
          {/* <OutboundLink href={this.props.data.websiteUrl} rel="noreferrer" target="_blank"></OutboundLink> */}
          <div className="page regional-sponsors-widget">
            <h3>Sponsors</h3>
            <ul>
              {this.props.data.sponsoredKeys ? this.props.data.sponsoredKeys.map((sponsor, index) => {
                return (
                  <li key={index}>
                      <img src={sponsor} alt="sponsor" />
                  </li>
                )
              }) : null }
            </ul>
          </div>

        </section>
        {/* <div id="share-button">
          <a
            href="#"
            className="button"
            data-share-email="mailto:?subject=TITLE&amp;body=URL"
            data-share-facebook="http://www.facebook.com/share.php?u=URL"
            data-share-twitter="https://twitter.com/intent/tweet?text=TITLE&amp;url=URL"
          >
            Share This Page
          </a>
        </div> */}
   
    

</>

    )
  }
}