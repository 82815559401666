import React, { Component } from "react";
import { Router, Link } from "@reach/router";
import Seo from "../seo";
import Overview from "./region-overview";
import About from "./region-about";
import Loading from "../loading-spinner";
import StaticBG from "../../assets/images/uploads/partner-vermont-hero.jpg";

export default class SingleRegion extends Component {
  state = {
    region: {},
    documents: [], // Initialize documents in state
    zip: "",
    interest: "",
    heroStatus: true,
  };

  async getSingleRegion(code) {
    try {
      let response = await fetch(process.env.GATSBY_PORTAL_REGION + "/" + code);
      let data = await response.json();
      console.log("🚀 ~ getSingleRegion response data:", data); // Check if documents exist here
      return data;
    } catch (error) {
      console.error("Error fetching single region:", error);
    }
  }

  componentDidMount() {
    const code = localStorage.getItem("selectedShortCode") || this.props.id;
    this.getSingleRegion(code)
      .then((data) => {
        console.log("🚀 ~ fetched region data:", data);
        this.setState({
          region: data,
          documents: data.documents || [], // Ensure documents are set here
          zip: localStorage.getItem("zip"),
          interest: parseInt(localStorage.getItem("interest"), 10),
          heroStatus: false,
        });
      })
      .catch((error) => {
        console.error("Error during componentDidMount:", error);
      });
  }

  componentDidUpdate(prevProps) {
    // Update state if interest changes
    if (prevProps.interest !== this.props.interest) {
      const newInterest = this.props.interest;
      const newRegionShortCode = newInterest === 0
        ? localStorage.getItem("artRegionShortCode")
        : localStorage.getItem("writingRegionShortCode");

      this.getSingleRegion(newRegionShortCode)
        .then((data) => {
          this.setState({
            region: data,
            documents: data.documents || [],
            interest: newInterest,
          });
        })
        .catch((error) => {
          console.error("Error during componentDidUpdate:", error);
        });
    }
  }

  render() {
    if (this.state.heroStatus) {
      return <Loading />;
    } else {
      return (
        <div id="region">
          {this.state.region.name ? (
            <Seo
              title={this.state.region.name}
              description={`${this.state.region.name} is managed by the ${this.state.region.partnerName}`}
            />
          ) : null}
          <nav
            id="nav-section"
            aria-expanded={this.props.navSection ? "true" : "false"}
            style={this.props.navSection ? { display: "block" } : null}
          >
            <ul>
              <li>
                <Link to="./">Overview</Link>
              </li>
              <li>
                <Link to="about">About this Region</Link>
              </li>
              <li>
                <Link to="../">Change my Region</Link>
              </li>
            </ul>
          </nav>
          <section id="region-hero" className="partner-header">
            <div
              className="image"
              style={
                this.state.region.bannerKey
                  ? { backgroundImage: `url(${this.state.region.bannerKey})` }
                  : { backgroundImage: `url(${StaticBG})` }
              }
            ></div>
            <div className="overlay">
              <h2>{this.state.region.name}</h2>
              {this.state.zip && <div className="zip">Serving Zip Code {this.state.zip}</div>}
              <div className="switch">
                {this.state.zip ? (
                  this.state.interest === 0 ? (
                    <a onClick={this.props.switchRegion} className="button">
                      Switch to Writing
                    </a>
                  ) : this.state.interest === 1 ? (
                    <a onClick={this.props.switchRegion} className="button">
                      Switch to Art
                    </a>
                  ) : null
                ) : (
                  <a onClick={this.props.findRegion} className="button">
                    Find a New Region
                  </a>
                )}
              </div>
            </div>
          </section>

          <Router>
            <Overview path="/" data={this.state.region} documents={this.state.documents} interest={this.state.interest} />
            <About path="/about" data={this.state.region} interest={this.state.interest} zip={this.state.zip} />
          </Router>
        </div>
      );
    }
  }
}